import React, { useState } from "react";
import { Card, Button, Modal } from "antd";
import { styled } from "styled-components";
import "../styles.css";
import Ambiente from "./Ambiente";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFileInvoiceDollar, faGear } from "@fortawesome/free-solid-svg-icons";

const Sobre = () => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const handleOpenModal = (component) => {
    setModalContent(component);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setModalContent(null);
  };


  const handleContactClick = () => {
    window.open("https://api.whatsapp.com/send?1=pt_BR&phone=5511963697271&text=Olá", "_blank");
  };


  return (
    <Container>
      <Card style={{ width: "85%", textAlign: "center",  backgroundColor: "transparent" }}>
       <center>
        <ButtonStyled  onClick={() => handleOpenModal(<Ambiente handleCloseModal={handleCloseModal} />)} style={{ backgroundColor: '#FFA500' }}>
        <FontAwesomeIcon icon={faGear} size="2x" />
        <ButtonText>Ambiente</ButtonText>
      </ButtonStyled>


      <ButtonStyled  onClick={() => handleContactClick()} style={{ backgroundColor: '#030556'}}>
        <FontAwesomeIcon icon={faGear} size="2x" />
        <ButtonText>Suporte Técnico</ButtonText>
      </ButtonStyled>
      </center>
      </Card>
      <Modal
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width="100vw"
        bodyStyle={{ padding: 0 }}
        centered
        closeIcon={null} // Remove o ícone de fechamento padrão
      >
        <ModalHeader>
          <Button onClick={handleCloseModal} type="link" icon={<FontAwesomeIcon icon={faArrowLeft} />} />
        </ModalHeader>
        <ModalBody>{modalContent}</ModalBody>
      </Modal>
    </Container>
  );
};

const ButtonStyled = styled(Button)`
  width: 150px;
  height: 150px;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  margin: 10px;
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  position: relative;
`;


const ButtonText = styled.span`
  margin-top: 10px;
  font-weight: bold;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 0;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 16px;
  background: #fff;
`;

const ModalBody = styled.div`
  padding: 0;
  margin:0;
  height: calc(100vh - 19vh); // Ajusta a altura do corpo do modal
`;


export default Sobre;
