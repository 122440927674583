import React, { useState } from "react";
import { Card, Button, Modal, Row, Col } from "antd";
import { styled } from "styled-components";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFileInvoiceDollar, faGear } from "@fortawesome/free-solid-svg-icons";
import EnviarNotasTela from "./operacoes/EnviarNotasTela";


const Operacoes = () => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const accessToken = window.localStorage.getItem("accessToken");

  const handleOpenModal = (component) => {
    setModalContent(component);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setModalContent(null);
  };

  

  const handleContactClick = () => {
    window.open("https://api.whatsapp.com/send?1=pt_BR&phone=5511963697271&text=Olá", "_blank");
  };

  const abrirCadastroDeProdutos = () => {
    window.open(`https://technotafiscal.technfe.com.br?tokenUrl=${accessToken}`, "_blank");
  };



  return (
    <Container>
      <Card style={{ width: "85%", textAlign: "center", backgroundColor: "transparent" }}>
        <Row gutter={[16, 16]} justify="center">
   
            <ButtonStyled onClick={() => {
              window.open('https://techunion.invoicy.com.br/', "_blank");
            }} style={{ backgroundColor: '#841584', width: '150px', height: '150px' }}>
              <FontAwesomeIcon icon={faGear} size="2x" />
              <ButtonText> Nota Fiscal NFE-DANFE</ButtonText>
            </ButtonStyled>
  


            <ButtonStyled onClick={() => abrirCadastroDeProdutos()} style={{ backgroundColor: '#FFA500', width: '150px', height: '150px' }}>
              <FontAwesomeIcon icon={faGear} size="2x" />
              <ButtonText>Cadastro de Produtos</ButtonText>
            </ButtonStyled>
   

    
            <ButtonStyled onClick={() => handleOpenModal(<EnviarNotasTela handleCloseModal={handleCloseModal} />)} style={{ backgroundColor: '#4682B4', width: '150px', height: '150px' }}>
              <FontAwesomeIcon icon={faGear} size="2x" />
              <ButtonText>Envio de Notas</ButtonText>
            </ButtonStyled>

   
            <ButtonStyled onClick={() => handleContactClick()} style={{ backgroundColor: '#32CD32',width: '150px', height: '150px' }}>
              <FontAwesomeIcon icon={faGear} size="2x" />
              <ButtonText>Relatórios</ButtonText>
            </ButtonStyled>

        </Row>
      </Card>

      <Modal
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width="100vw"
        bodyStyle={{ padding: 0 }}
        centered
        closeIcon={null} // Remove o ícone de fechamento padrão
      >
        <ModalHeader>
          <Button onClick={handleCloseModal} type="link" icon={<FontAwesomeIcon icon={faArrowLeft} />} />
        </ModalHeader>
        <ModalBody>{modalContent}</ModalBody>
      </Modal>
    </Container>
  );
};


const ButtonStyled = styled(Button)`
  width: 150px;
  height: 150px;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  margin: 10px;
  text-align: center;
  padding: 10px;
  overflow: hidden; /* Esconde qualquer conteúdo que ultrapasse o limite do botão */
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const ButtonText = styled.span`
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  word-wrap: break-word; /* Quebra o texto para a próxima linha, se necessário */
  width: 100%;
  white-space: normal; /* Permite quebra de linha */
  overflow-wrap: break-word; /* Garante a quebra de palavra se for muito longa */
  display: block;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  position: relative;
`;


const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 0;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 16px;
  background: #fff;
`;

const ModalBody = styled.div`
  padding: 0;
  margin:0;
  height: calc(100vh - 19vh); // Ajusta a altura do corpo do modal
`;


export default Operacoes;
