import React, { useState } from 'react';
import { Modal } from 'antd';

import { QrReader } from 'react-qr-reader';


const QrCodeModal = ({ isModalVisibleLeitor, toggleModalLeitor }) => {
  const [result, setResult] = useState('');
  const [error, setError] = useState('');

  const handleScan = (data) => {
    if (data) {
      setResult(data);
      toggleModalLeitor(); // Fecha o modal após a leitura do QR code
    }
  }

  const handleError = (err) => {
    console.error(err);
    setError('Erro ao ler o QR code.');
  }

  return (
    <Modal
      visible={isModalVisibleLeitor}
      onCancel={toggleModalLeitor}
      footer={null}
    >
      <div style={{ textAlign: 'center' }}>
        <h2>Leitor de QR Code</h2>
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
        <p>{result ? `QR Code lido: ${result}` : error}</p>
      </div>
    </Modal>
  );
}

export default QrCodeModal;
