import { Button, Input, List, Modal, Spin , DatePicker, Spac} from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import QrCodeModal from './QrCodeScanner';
import "../styles.css";

const Cancelamento = () => {
  const [isModalVisibleLeitor, setIsModalVisibleLeitor] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDateSelector, setShowDateSelector] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);




  const dispatch = useDispatch();
  const [termo, setTermo] = useState('hoje');
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");   
  const [restauranteId, setRestauranteId] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setotTotalPages] = useState(0);
  const [buscaPorData, setBuscaPorData] = useState(false);
  const [baixarImprimirPdf, setBaixarImprimirPdf] = useState(true);
  const [loja, setLoja] = useState('');
  const tpAmb = window.localStorage.getItem('tpAmb');
  const [dates, setDates] = useState(null);


const { RangePicker } = DatePicker;



  useEffect(() => {
    
    if (accessToken && idUser) {

      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      axios
        .get(
          `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
          options,
        )
        .then(restaurante => {
          setLoja(restaurante.data);
        });
    }
  }, [idUser, accessToken]);





  const toggleDateSelector = () => {
    setShowDateSelector(!showDateSelector);
  };

  const toggleModalLeitor = () => {
    setIsModalVisibleLeitor(!isModalVisibleLeitor);
  };

  const buscaNotas = async termo => {
    setBuscaPorData(false);
    setTermo(termo);
    setCurrentPage(0);

    if (accessToken) {
      console.log('dentro da busca');

      try {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        };

        const response = await axios.get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipos/${loja.id}/${termo}/${tpAmb}/0,3?page=0`,
          options,
        );

        if (response.data._embedded.PDVEvento.length > 0) {
          setProducts(response.data._embedded.PDVEvento);
          setotTotalPages(response.data.page.totalPages);
          setCurrentPage(response.data.page.number);
        } else {
          setCurrentPage(0);
          setProducts([]);
        }
      } catch (error) {
        setProducts([]);
        console.error('Erro ao carregar cupomFiscal:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };


  const loadCupons = async () => {
    if (accessToken && termo) {
      try {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        };

        if (buscaPorData) {
          var response = '';
          console.log('loadCupons data()');

          console.log('Data inicial:', dates[0].format('YYYY-MM-DD'));
          console.log('Data final:', dates[1].format('YYYY-MM-DD'));
          const dataInicio = dates[0].toISOString().split('T')[0];
          const dataFim = dates[1].toISOString().split('T')[0];

          response = await axios.get(
            `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/dataTipos/${
              loja.id
            }/${dataInicio}/${dataFim}/${tpAmb}/0,3?page=${currentPage + 1}`,
            options,
          );
        } else {

          response = await axios.get(
            `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipos/${
              loja.id
            }/${termo}/${tpAmb}/0,3?page=${currentPage + 1}`,
            options,
          );
        }

        if (response.data._embedded?.cupomFiscal.length > 0) {
          setotTotalPages(response.data.page.totalPages);
          setCurrentPage(response.data.page.number);

          if (response.data.page.number !== 0) {
            console.log('adicionando nova pagina');
            setProducts([...products, ...response.data._embedded.PDVEvento]);
          } else {
            console.log('Adicionando array limpo');
            setProducts(response.data._embedded.PDVEvento);
          }
        } else {
          setCurrentPage(0);
          setProducts([]);
        }
      } catch (error) {
        console.error('Erro ao carregar historico:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };




  function formatarCpfCnpj(cpfCnpj) {
    cpfCnpj = cpfCnpj.replace(/\D/g, '');

    if (cpfCnpj.length === 11) {
      return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (cpfCnpj.length === 14) {
      return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else {
      return cpfCnpj;
    }
  }

  function formatarValorEmReais(valor) {
    const simboloMoeda = 'R$';
    const valorFormatado = parseFloat(valor).toFixed(2).replace('.', ',');

    const partes = valorFormatado.split(',');
    partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return simboloMoeda + ' ' + partes.join(',');
  }




  const downloadPDF = (b64, chaveDocFiscal) => {
    // Decode Base64 to binary data
    const binaryData = atob(b64);
  
    // Create a Uint8Array from the binary data
    const byteNumbers = new Uint8Array(
      new Array(binaryData.length).fill().map((_, i) => binaryData.charCodeAt(i))
    );
  
    // Create a Blob from the byte array
    const blob = new Blob([byteNumbers], { type: 'application/pdf' });
  
    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `PDF_${chaveDocFiscal}.pdf`;
  
    // Append the link to the document and click it to trigger the download
    document.body.appendChild(link);
    link.click();
  
    // Clean up by removing the link and revoking the object URL
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  
    // Optionally, you can notify the user that the file is being downloaded
    alert('O arquivo PDF está sendo baixado.');
  };


const printPDF = (base64Data) => {
  // Decodifica a string Base64 para um ArrayBuffer
  const binaryString = window.atob(base64Data);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Criar um Blob com o conteúdo do PDF
  const blob = new Blob([bytes], { type: 'application/pdf' });

  // Criar uma URL para o Blob
  const url = window.URL.createObjectURL(blob);

  // Abrir o PDF em uma nova aba
  const newWindow = window.open(url);

  // Quando a nova aba carregar, chamar a função de impressão
  if (newWindow) {
    newWindow.onload = function () {
      newWindow.focus(); // Focar na nova aba
      newWindow.print(); // Chamar a função de impressão
    };
  } else {
    // Se a nova aba não puder ser aberta
    alert('Permita pop-ups para abrir o PDF');
  }
};




const cancelarEvento = async item => {

  const options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  };

  const body = {
    mensagem: 'Cancelado pois haviam erros no documento.',
  };

  let msgErro = '';
  let codErro = '';
  let response2 = false;

if(item.tipoEvento === 'PEDIDO'){
 response2 = await axios
.put(
  `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/cancelarId/${item.id}`,
  body,
  options,
)

.catch(error => {
  msgErro = error.response.data.detail;
  codErro = error.response.status;
  response2 = false;
});

}else{
   response2 = await axios
    .put(
      `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/cancelar/${item.chaveDocFiscal}`,
      body,
      options,
    )

    .catch(error => {
      msgErro = error.response.data.detail;
      codErro = error.response.status;
      response2 = false;
    });
  }

  if (response2.data == true) {
    window.alert('Evento cancelado com sucesso');
    

        const vendasArray = window.localStorage.getItem('vendasNaoSalvas')
          ? JSON.parse(window.localStorage.getItem('vendasNaoSalvas'))
          : [];
        // Filtra o array para excluir o item com a chave de acesso correspondente
        const novoArray = vendasArray.filter(
          venda => venda.InfoSistema.chaveDocFiscal !== item.chaveDocFiscal,
        );

        window.localStorage.setItem('vendasNaoSalvas', JSON.stringify(novoArray));
            console.log(window.localStorage.getItem('vendasNaoSalvas'));
            console.log(
              'Item removido com sucesso do array vendasNaoSalvas.',
            );


    setIsLoading(false);
    buscaNotas('hoje');
  } else {
    const confirmed = window.confirm(
      'Falha ao cancelar evento nota.' + codErro+ ' Deseja tentar novamente?'
    );
    if (confirmed) {
      console.log('Tentar novamente');
      cancelarEvento(item);
    }else{
      setIsLoading(false);
      buscaNotas('hoje');
      return;
    }
  }
};

const handleItemPress = async item => {
console.log(item);
  if(item.tipoEvento === 'PEDIDO'){
    const confirmed = window.confirm("Deseja realmente alterar a senha?");
    if (confirmed) {
      const confirmed2 = window.confirm(
        "Tem certeza que deseja cancelar este pedido?"
      );
      if (confirmed2) {
        console.log('Cancelando pedido');
        await cancelarEvento(item);
      } else {
        return;
      }
    } else {
      return;
    }
  }


  try {
    const confirmed = window.confirm("Tem certeza que deseja cancelar esta nota?");
    if (confirmed) {
      setIsLoading(true);
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      const body = {
        mensagem: 'Cancelado pois haviam erros no documento.',
      };

      const response = await axios
        .post(
          `https://technfe-api.herokuapp.com/v1/nota/cancelarNota/${item.chaveDocFiscal}`,
          body,
          options,
        )

        .catch(error => {
          if (error.response) {
            console.log('Status de erro:', error.response.status);
            console.log('Dados de erro:', error.response.data);

            const confirmed2 = window.confirm("Falha ao cancelar a nota, deseja tentar novamente?");
            if (confirmed2) {

              console.log('Tentar novamente');
              handleItemPress(item);
            }else{
              setIsLoading(false);
              buscaNotas('hoje');
              return;
            }
   
          }
        });

      if (response.data.documento.sitCodigo == '101') {
        console.log('entrei no if');
        const docFiscalObject = JSON.parse(item.jsonDocFiscal);
        const docDhAut = docFiscalObject.InfoSistema.docDhAut;

        const itemMontado = {
          cancelamento: true,
          InfoSistema: {
            numeroDocFiscal: item.numeroDocFiscal, 
            serieDocFiscal: item.serieDocFiscal, 
            docDhAut: docDhAut,
            chaveDocFiscal: item.chaveDocFiscal,
          },
          dest: {
            CPF_dest: item.cpf, 
          },
          total: {
            ICMStot: {
              vNF: item.valorTotalLiquido, 
            },
          },
        };
        console.log('438');
        console.log( window.localStorage.getItem('vendasNaoSalvas'));
        



       
            const vendasArray = window.localStorage.getItem('vendasNaoSalvas')
              ? JSON.parse(window.localStorage.getItem('vendasNaoSalvas'))
              : [];

            // Verifica se já existe um item com a mesma chaveDocFiscal
            const chaveDocFiscalExiste = vendasArray.some(
              venda =>
                venda.InfoSistema.chaveDocFiscal ===
                item.chaveDocFiscal,
            );

            // Se a chaveDocFiscal não existir, adiciona o novo item ao array
            if (!chaveDocFiscalExiste) {
              vendasArray.push(itemMontado);
              window.localStorage.setItem(
                'vendasNaoSalvas',
                JSON.stringify(vendasArray),
              );
            } else {
              console.log(
                'Item ignorado pois já existe um item com a mesma chaveDocFiscal.',
              );
            }        


        //await window.alert("Nota cancelada com sucesso");

        await cancelarEvento(item);
      } else {
        if (
          response.data.documento.sitCodigo != null ||
          response.data.documento.sitCodigo != '' ||
          response.data.documento.sitCodigo != null ||
          response.data.documento.sitCodigo != undefined
        ) {
          // Verificar se sitCodigo não é nulo nem vazio
          window.alert(
            'Erro ao cancelar a nota! ' +
              response.data.documento.sitDescricao,
          );
          buscaNotas('hoje');
        } else {
          console.log(response.data.documento.SitDescricao);
          window.alert('Erro ao cancelar a nota');
          buscaNotas('hoje');
        }
      }

    }

  } catch (error) {
    window.alert('Erro ao cancelar a Nota' + error);
    console.error('Erro ao carregar historico:', error);
    setIsLoading(false);
    buscaNotas('hoje');
  } finally {
    setIsLoading(false);
    buscaNotas('hoje');
  }


};

      const handleDateChange = (dates) => {
        setDates(dates);
        if (dates && dates[0] && dates[1]) {
         setBuscaPorData(true);
         handleSaveDates(dates);
        }
      };

      const buscarPorCodChavCpf = async () => {
        if (accessToken && searchTerm !== null) {
          try {
            const options = {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken,
              },
            };

          const response = await axios.get(
              `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/chavenumerotipo/${loja.id}/${searchTerm}/0/${tpAmb}`,
              options,
            );

            if (response.data._embedded?.PDVEvento.length > 0) {
              setProducts(response.data._embedded.PDVEvento);
            } else {
              setCurrentPage(0);
              setProducts([]);
            }
          } catch (error) {
            console.error('Erro ao carregar historico:', error);
          } finally {
            setIsLoading(false);
          }
        }
      };

      const handleSaveDates = async (datesp) => {
        setBuscaPorData(true);
        setIsLoading(true);
        const dataInicio = datesp[0].toISOString().split('T')[0];
        const dataFim = datesp[1].toISOString().split('T')[0];
    
        if (accessToken) {
          console.log('dentro da busca');
    
          try {
            const options = {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken,
              },
            };
            const response = await axios.get(
              `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/dataTipos/${loja.id}/${dataInicio}/${dataFim}/${tpAmb}/0,3?page=0`,
              options,
            );
    
            if (response.data._embedded.PDVEvento.length > 0) {
              setProducts(response.data._embedded.PDVEvento);
              setotTotalPages(response.data.page.totalPages);
              setCurrentPage(response.data.page.number);
            } else {
              setCurrentPage(0);
              setProducts([]);
            }
          } catch (error) {
            setProducts([]);
            console.error('Erro ao carregar cupomFiscal:', error);
          } finally {
            setIsLoading(false);
          }
        }
      };

  const renderItem = ({ item }) => {
    const formattedDate = new Date(item.dataHoraFechamento).toLocaleString('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    return (
      <Button style={styles.card}
      onClick={() => handleItemPress(item)}
      >
        <span style={styles.cardText}>{item.tipoEvento}</span>
        {item.tipoEvento === 'VENDA' && (
            <span style={styles.cardText}>NF: {item.numeroDocFiscal}</span>
        )}

        {item.tipoEvento === 'VENDA' && (       
             <span style={styles.cardText}>Série: {item.serieDocFiscal}</span>            
        )}

        <span style={styles.cardText}>Data: {formattedDate}</span>
        <span style={styles.cardText}>
          Cliente: {item.cpf ? formatarCpfCnpj(item.cpf) : ''}
        </span>
        <span style={styles.cardText}>
          Valor: {formatarValorEmReais(item.valorTotalLiquido)}
        </span>
      </Button>
    );
  };

  return (
    <>
      <div style={styles.container}>
        <Modal
          visible={isModalVisibleLeitor}
          onCancel={toggleModalLeitor}
          footer={null}
        >
          <div style={styles.modalContainer}>
            
          </div>
        </Modal>
        <QrCodeModal></QrCodeModal>

        <div style={styles.buttonContainer}>
          <Button
            style={{ ...styles.selectButton, backgroundColor: 'black' }}
            loading={isLoading}
            disabled={!loja}
            onClick={() => {
              //toggleDateSelectorFalse();
              setIsLoading(true);
              setCurrentPage(0);
              buscaNotas('hoje');
            }}
          >
            <span style={styles.buttonText}>Hoje</span>
          </Button>
          <Button
            style={{ ...styles.selectButton, backgroundColor: 'black' }}
            loading={isLoading}
            disabled={!loja}
            onClick={() => {
              setIsLoading(true);
              setCurrentPage(0);
              console.log('Após clicar em ontem');
              buscaNotas('ontem');
            }}
          >
            <span style={styles.buttonText}>Ontem</span>
          </Button>
          <Button
            style={{ ...styles.selectButton, backgroundColor: 'black' }}
            loading={isLoading}
            disabled={!loja}
            onClick={() => {
              setIsLoading(true);
              setCurrentPage(0);
              buscaNotas('semana');
            }}
          >
            <span style={styles.buttonText}>Semana</span>
          </Button>
          {/*<Button
            style={{ ...styles.selectButton, backgroundColor: 'black' }}
            loading={true}
            onClick={toggleDateSelector}
            disabled={true}
          >
            <span style={styles.buttonText}>Escolha</span>
          </Button>
         
     */}
   <RangePicker onChange={handleDateChange} />
   </div>

        <div style={styles.searchContainer}>
          <Input
            style={styles.input}
            placeholder="Pesquisar por EAN, Nome ou Código Interno"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <Button
            style={{ ...styles.searchButton, backgroundColor: 'black' }}
            loading={isLoading || !loja}
            disabled={!loja}
            onClick={async ()  => {
              setIsLoading(true);
             await buscarPorCodChavCpf();
            }}
          >
            <span style={styles.buttonText}>Pesquisar</span>
          </Button>
        </div>

        {isLoading ? (
          <center><Spin size="large" /></center>
        ) : (
          <List
          style={{ maxHeight: '650px', overflowY: 'auto' }} // Define uma altura máxima e permite rolagem vertical
          dataSource={products}
          renderItem={product => renderItem({ item: product })}
        />
        )}
      </div>
    </>
  );
};

const styles = {
  container: {
    padding: '16px',
  
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  selectButton: {
    flex: 1,
    padding: '20px',
    margin: '5px',
    textAlign: 'center',
    borderRadius: '5px',
    display: 'flex',           
    justifyContent: 'center',  
    alignItems: 'center',      
  },
  selectButton2: {
    flex: 1,
    padding: '20px',
    margin: '5px',
    textAlign: 'center',
    borderRadius: '5px',
    display: 'flex',           
    justifyContent: 'center',  
    alignItems: 'center',     
  },
  buttonText: {
    color: 'white',
    fontSize: '16px',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '16px',
  },
  input: {
    flex: 1,
    padding: '10px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ccc',
    borderRadius: '5px',
  },
  searchButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '21px',
    marginLeft: '10px',
    borderRadius: '5px',
    
  },
  card: {
    padding: '30px',
    margin: '8px 16px',
    borderRadius: '5px',
   backgroundColor: '#f9f9f9',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ddd',
    width:'100%',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-around'
  },
  cardText: {
    fontSize: '14px',
    color: '#333',
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
};

export default Cancelamento;
