import React, { useCallback, useEffect, useState } from 'react';
import { Button, Layout, Spin, Typography, message } from 'antd';
import { ReloadOutlined, StopOutlined } from '@ant-design/icons';
import axios from 'axios';


const { Header, Content } = Layout;
const { Text } = Typography;

const TodosPagamentosDeVenda = ({ setErroBuscarGraficos, erroBuscarGraficos, lojaId }) => {

  const accessToken = window.localStorage.getItem("accessToken");
  const tpAmb = window.localStorage.getItem('tpAmb');
  const [urlGrafico, setUrlGrafico] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [reloadKey, setReloadKey] = useState(0);


  const reloadFocus = useCallback(() => {
    if (tpAmb && accessToken && lojaId) {
      handleReload();
    }
  }, [tpAmb, accessToken, lojaId]);

  useEffect(() => {
    reloadFocus();
  }, [reloadFocus]);

  const buscarGraficos = () => {
    if (accessToken && tpAmb && lojaId) {
      console.log('buscando url');
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      const body = {
        dashboard: 65,
        params: {
          ambiente: tpAmb,
          empresa: lojaId,
        },
        paramsURL: `?entre=${getData1()}~${getData1()}`,
        dominio: `https://metabase.techspdv.com.br`,
      };

      axios
        .post(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/metabase/gerarDashboard`,
          body,
          options,
        )
        .then((resp) => {
          console.log(resp.data);
          if (resp.data === '') {
            setErroBuscarGraficos(true);
          } else {
            setErroBuscarGraficos(false);
            setUrlGrafico(resp.data);
            console.log(resp.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('Status de erro:', error.response.status);
            console.log('Dados de erro:', error.response.data);
            message.error('Erro ao buscar o gráfico: ' + error.response.status);
            setErroBuscarGraficos(true);
            setIsLoading(false);
          }
        });
    }
  };

  const getData1 = () => {
    const hojeUTCmenos3 = new Date();
    const ano = hojeUTCmenos3.getFullYear();
    const mes = hojeUTCmenos3.getMonth() + 1;
    const dia = hojeUTCmenos3.getDate();
    return `${ano}-${mes < 10 ? '0' : ''}${mes}-${dia < 10 ? '0' : ''}${dia}`;
  };

  const handleLoadError = (error) => {
    console.log('Erro de carregamento:', error);
  };

  const handleReload = () => {
    setIsLoading(true);
    buscarGraficos();
    setErroBuscarGraficos(false);
  };

  const handleCancel = () => {
    setErroBuscarGraficos(true);
    setIsLoading(false);
  };


  return (
    <Layout>

      <center>
        <div style={{ marginTop: 25 }}>
          {isLoading ? (
            <Button type="primary" onClick={handleCancel} icon={<StopOutlined />} />
          ) : (
            <Button type="primary" onClick={handleReload} icon={<ReloadOutlined />} />
          )}
        </div>
      </center> 
      <Content style={{ padding: 24, backgroundColor: '#fff', minHeight: '80vh' }}>
  {!erroBuscarGraficos ? (
    <div style={{ height: '100%' }}>
      {isLoading && <Spin size="large" />}
      {urlGrafico !== '' && (
        <iframe
          key={reloadKey}
          src={urlGrafico}
          title="Gráfico"
          style={{ width: '100%', height: 'calc(100vh - 96px)', border: 'none' }}
          onError={handleLoadError}
        />
      )}
    </div>
  ) : null}
</Content>

    </Layout>
  );
};

export default TodosPagamentosDeVenda;
