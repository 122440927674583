import React, { useState, useEffect, useCallback } from "react";
import { Tabs } from "antd";

import {
  UserOutlined,
  HomeOutlined,
  ReadOutlined,
  TeamOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

import Home from "./Home.js/Home";
import styled from "styled-components";
import { Link, useHistory, useParams } from "react-router-dom";
import Sair from "./Sair";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faGear,
  faHome,
  faImage,
  faList,
  faRightFromBracket,
  faScrewdriverWrench,
  faShop,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import "./TabStyles.css";
import "./styles.css";
import PageHeader from "./pageHeader/PageHeader";
import axiosInstance from "../../axiosInterceptor";
import jwtDecode from "jwt-decode";
import Sobre from "./habitualidades/Sobre";
import Vendas from "./VendasView/Vendas";
import Operacoes from "./Operacoes";
const { TabPane } = Tabs;
const TopTab = () => {
  const history = useHistory();
  const [activeKey, setActiveKey] = useState("inicio");
  const [tabName, setTabName] = useState("Início");
  const [loja, setLoja] = useState(null);
  const idUser = window.localStorage.getItem("idUser");
  const accessToken = window.localStorage.getItem("accessToken");

  const [nomeUsuarioLogado,setNomeUsuarioLogado] = useState('');
  const [nomeLoja,setNomeLoja] = useState('');

  const [recarregarConfiguracoes,setRecarregarConfiguracoes] = useState(true);
  const [tpAmb, setTpAmb] = useState(window.localStorage.getItem('tpAmb')? window.localStorage.getItem('tpAmb') : '2');
  
  console.log(window.localStorage.getItem('tpAmb'));

  useEffect(() => {
    if (!idUser) return;
  
    const fetchData = async () => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      if (!loja) {
        const resp = await axiosInstance.get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options);
        setNomeLoja(resp.data.nome);
        setNomeUsuarioLogado(jwtDecode(accessToken).nome_completo);
      }
    };
    fetchData();
  }, [idUser]);

  const handleTabClick = (key) => {
    console.log('key',key);
    if (key === "inicio") {
      setTabName("Início");
    }

    if (key === "5") {
      setTabName("Sair");
      const confirmed = window.confirm("Deseja sair?");
      if (confirmed) {
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("idUser");
        history.push("/");
        window.location.reload();
      } else {
        setActiveKey("inicio");
        setTabName("Início");
      }
    } else {
      setActiveKey(key);
    }
  };



  return (
    <Container>
      <PageHeader tabName={tabName} nomeLoja={nomeLoja} nomeUsuarioLogado={nomeUsuarioLogado}  />
      <Tabs
        activeKey={activeKey}
        tabBarGutter={20}
        centered
        styled={{ width: "100%" }}
        onTabClick={handleTabClick}
        tabBarStyle={{
          backgroundColor: "black",
          color: "white",
          activeTab: "#43e97b",
          margin: 0,
          position: "fixed",
          zIndex: 99,
          width: "100%",
          bottom: 0,
        }}
      >
        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faHome}
                style={{ width: "25px", height: "25px" }}
              />
              Inicio
            </span>
          }
          key="inicio"
        >
          <Home></Home>
        </TabPane>
        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                 icon={faList}
                style={{ width: "25px", height: "25px" }}
              />
              Vendas
            </span>
          }
          key="vendas"
        >
          <Vendas></Vendas>
        </TabPane>
        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faScrewdriverWrench}
                style={{ width: "25px", height: "25px" }}
              />
              Operações
            </span>
          }
          key="2"
        >
          <Operacoes></Operacoes>
        </TabPane>
        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faGear}
                style={{ width: "25px", height: "25px" }}
              />
              Configurações
            </span>
          }
          key="configuracoes"
        >
          <Sobre></Sobre>
        </TabPane>
        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faRightFromBracket}
                style={{ width: "25px", height: "25px" }}
              />
              Sair
            </span>
          }
          key="5"
        ></TabPane>
 
      </Tabs>
    </Container>
  );
};
const Container = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const TabsContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 1; /* Garante que as abas fiquem acima do conteúdo */
`;

const CentralizedText = styled.div`
  text-align: center;
  color: white;
  font-size: 14px;
  margin-top: 10px;
  width:'100%';
`;
export default TopTab;
