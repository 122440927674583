import React, { useEffect, useState } from 'react';
import { Select, DatePicker, Button, Card, Row, Col, List, Spin, Form, Input, message, notification } from 'antd';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt-br'; // Importa o idioma português
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk, faPaperPlane, faSeedling } from '@fortawesome/free-solid-svg-icons';

moment.locale('pt-br'); // Define o idioma padrão como português

const { Option } = Select;
const { RangePicker } = DatePicker;

const EnviarNotasTela = () => {
  const [transacaoPendente, setTransacaoPendente] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [dateRange, setDateRange] = useState([]);
  const [cardsData, setCardsData] = useState([]);
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");   
  const tpAmb = window.localStorage.getItem('tpAmb');
  const [loja, setLoja] = useState('');
  const [totalAutorizados, setTotalAutorizados] = useState(0);
  const [totalCancelados, setTotalCancelados] = useState(0);
  const [totalInutilizados, setTotalInutilizados] = useState(0);

  const [historico, setHistorico] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (accessToken && idUser) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };
      axios
        .get(
          `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
          options,
        )
        .then(async restaurante => {
          setLoja(restaurante.data);
        });
    }
  }, [idUser, accessToken]);





  useEffect(() => {
    async function fetchData() {
      console.log(transacaoPendente);
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };
      if (transacaoPendente === true && loja.id) {
        var cont = 0;
        var resp = await axios.get(`https://technfe-api.herokuapp.com/v1/historico/getTransacaoPendente/${loja.id}`, options);
        if (resp.data.length > 0) {
          window.alert('Existem notas sendo processadas.');
        }
        while (cont < 6) {
          cont++;
           resp = await axios.get(`https://technfe-api.herokuapp.com/v1/historico/getTransacaoPendente/${loja.id}`, options);

          if (resp.data.length === 0) {
            cont = 7;
            setTransacaoPendente(false);
          } else {
            notification.info({
              message: 'Informação',
              description: 'Existem notas sendo processadas.',
            });
            if (cont >= 6) {
              if (
                window.confirm(
                  'Deseja cancelar o processamento?\nClique Ok para cancelar'
                ) === true
              ) {

                await axios.delete(`https://technfe-api.herokuapp.com/v1/historico/finalizarTransacoes/${loja.id}`, options);
                cont = 0;
              } else {
                cont = 0;
              }

              cont = 0;
            }
          }
          await new Promise((resolve) => setTimeout(resolve, 20000));
        }

        if (cont === 6) {
          window.alert('O Processamento ainda esta ocorrendo!');
        }
      }
    }

    fetchData();
  }, [loja, transacaoPendente]);
























  const renderItem = (item) => {
    console.log(item);
    const formattedDate = new Date(item.item.dataEmissao).toLocaleString('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    return (
      <Card 
        style={{
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 10,
        padding: 10,
        marginBottom: 10,
        width: '100%',
      }} key={item.numero} block>
        <span style={{
        fontWeight: 'bold',
        fontSize: 16,
        marginBottom: 5,
        color: '#000'
         }}>
          {item.item.tipoDocumento === 'NFCE_CUPOM' ? 'Cupom Fiscal' : item.item.tipoDocumento} n. {item.item.numero} {formattedDate}
        </span>
      </Card>
    );
  };


  // Handler para alteração do mês
  const handleMonthChange = (value) => {
    setSelectedMonth(value);
    setDateRange([]); // Limpa o intervalo de datas ao selecionar um mês
  };

  // Handler para alteração do intervalo de datas
  const handleDateRangeChange = (dates) => {
    setDateRange(dates || []); // Define um array vazio caso as datas sejam limpas
    setSelectedMonth(null); // Limpa o mês ao escolher um intervalo de datas
  };

  // Handler para geração dos dados
  const handleGenerate = async () => {

    if (accessToken && idUser  != null && loja && tpAmb) {
      setIsLoading(true);
      setTotalAutorizados(0);
      setTotalCancelados(0);
      setTotalInutilizados(0);

    try {
      let params = {};
      let urlBusca = '';
      if (selectedMonth !== null) {
        params = {
          month: selectedMonth,
        };
        urlBusca= `https://technfe-api.herokuapp.com/v1/historico/periodoMesXML/${selectedMonth+1}/${loja.cnpj}/${tpAmb}`
      } else if (dateRange.length === 2 && dateRange[0] && dateRange[1]) {
        const startDate = dateRange[0].format('YYYY-MM-DD');
        const endDate = dateRange[1].format('YYYY-MM-DD');

        urlBusca = `https://technfe-api.herokuapp.com/v1/historico/periodoXML/${startDate}/${endDate}/${loja.cnpj}/${tpAmb}`
        params = {
          startDate,
          endDate,
        };
      } else {
        console.error('Selecione um mês ou um intervalo de datas');
        return;
      }

      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      // Chamada ao endpoint GET com os parâmetros apropriados
      console.log(params);
      const response = await axios.get(urlBusca, options).then(xmls => {

        setHistorico(xmls.data._embedded != undefined ?xmls.data._embedded.historico : []);
      
         let totalAutorizadosAux = 0;
         let totalCanceladosAux = 0;
         let totalInutilizadosAux = 0;

         console.log('xmls.data');
         console.log('xmls.data');
         console.log(xmls.data._embedded);
         if(xmls.data._embedded != undefined){
           xmls.data?._embedded.historico.map((nota)=>{
             if(nota.status == 'AUTORIZADO'){
               totalAutorizadosAux += 1;      

             }
             
             if(nota.status == 'CANCELADO'){
               totalCanceladosAux += 1;      

             }

             if(nota.status == 'INUTILIZADO'){
               totalInutilizadosAux += 1;
  
             }
             
          })
         }
        setTotalAutorizados(totalAutorizadosAux);
        setTotalCancelados(totalCanceladosAux);
        setTotalInutilizados(totalInutilizadosAux);
        setIsLoading(false);
       });

      // Atualiza os dados dos cards com o resultado da pesquisa
      setCardsData(response.data);
    } catch (error) {
      console.error('Erro ao gerar notas:', error);
    }
   }
  };

  // Função para limpar o mês
  const clearMonth = () => {
    setSelectedMonth(null);
  };

  // Função para limpar o período
  const clearDateRange = () => {
    setDateRange([]);
  };

  // Verifica se os componentes devem ser desabilitados
  const isDateRangeDisabled = selectedMonth !== null;
  const isMonthDisabled = dateRange.length > 0;

  return (
    <div style={{ padding: '20px' }}>
      <h2>Enviar Notas</h2>
      <Card
        style={{
          borderWidth: 1,
          borderColor: '#ccc',
          borderRadius: 10,
          padding: 10,
          marginBottom: 10,
          justifyContent:'space-around',
          width:'100%',
          }}>
      <span style={{    
          fontWeight: 'bold',
          fontSize: 16,
          marginBottom: 5,
          color: '#000', 
        }}>Cupons: {`${totalAutorizados}`}</span><br/>
      <span style={{    
          fontWeight: 'bold',
          fontSize: 16,
          marginBottom: 5,
          color: '#000', 
        }}>Cancelamentos: {`${totalCancelados}`}</span><br/>
      <span style={{    
          fontWeight: 'bold',
          fontSize: 16,
          marginBottom: 5,
          color: '#000', 
        }}>Inutilizados: {`${totalInutilizados}`}</span>
     </Card>

     <Form layout="inline" style={{ width: '100%', marginTop:'15px', marginBottom:'15px' }}
     onFinish={ async (dados)  =>{
       if (accessToken && idUser) {
        setIsLoading(true);
        let deuErro = false;

        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        };

        const arrayIdsNotas = [];
        historico?.map((nota)=>{
          arrayIdsNotas.push(nota.id);
        })
        console.log(loja);
        console.log(loja.id);
 //return;
        const transactionId = await axios.get(`https://technfe-api.herokuapp.com/v1/historico/geraTransacao/${loja.id}`, options);
        console.log(transactionId);
        let cont = 0;
        let transactionInfo;

        axios.post(`https://technfe-api.herokuapp.com/v1/historico/enviarEmailXml2/${dados.email}/${transactionId.data}`,arrayIdsNotas, options)
        .then(() => {
          // Requisição enviada com sucesso, não faz nada com a resposta
        })
        .catch((error) => {
          // Opcional: logar o erro, mas não tratar a resposta
          console.error('Erro ao enviar a requisição:', error);
        });

        message.info('As notas estão sendo enviadas');
        setIsLoading(false);

        }
     }}>
      <Form.Item
        name="email"
        label="E-mail"
        rules={[{ required: true, message: 'Por favor, insira o e-mail' }]}
        style={{ marginRight: '10px' }}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button
           type="primary"
           htmlType="submit"
          style={{
            backgroundColor: '#000',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            padding: '10px 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '16px',
            fontWeight: 'bold',
            transition: 'background-color 0.3s, transform 0.2s',
            marginTop: '0' // Remove o espaço adicional acima do botão
          }}
        >
          <FontAwesomeIcon icon={faPaperPlane} size="lg" color='#fff' />
        </Button>
      </Form.Item>
    </Form>

      <Select
        style={{ width: 200, marginBottom: 20 }}
        placeholder="Selecione um mês"
        onChange={handleMonthChange}
        value={selectedMonth}
        allowClear
        onClear={clearMonth} // Garante que o mês seja limpo corretamente
        //disabled={isDateRangeDisabled} // Desativa o combo se um período estiver selecionado
      >
        {moment.months().map((month, index) => (
          <Option key={index} value={index}>
            {month.charAt(0).toUpperCase() + month.slice(1)} {/* Capitaliza o primeiro caractere */}
          </Option>
        ))}
      </Select>

      {/* Seletor de datas */}
      <RangePicker
        onChange={handleDateRangeChange}
        style={{ marginBottom: 20 }}
        value={dateRange.length ? dateRange : null}
        allowClear
        //disabled={isMonthDisabled} // Desativa o seletor de datas se um mês estiver selecionado
      />

      {/* Botão para gerar notas */}
      <Button type="primary" onClick={handleGenerate}>
        Gerar
      </Button>

      {isLoading ? (
          <center><Spin size="large" /></center>
        ) : (
          <List
          style={{ maxHeight: '400px', overflowY: 'auto' }}
          dataSource={historico}
          renderItem={nota => renderItem({ item: nota })}
        />
        )}
    </div>
  );
};



export default EnviarNotasTela;
