import React from "react";
import "./PageHeader.css"; // Importe seu arquivo de estilos CSS
import { Header } from "antd/es/layout/layout";

const PageHeader = ({ tabName, nomeLoja, nomeUsuarioLogado }) => {
  const tpAmb = window.localStorage.getItem('tpAmb');

  const getDataAtual = () => {
    const dataAtual = new Date();
    const dia = String(dataAtual.getDate()).padStart(2, '0');
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    const ano = dataAtual.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };


  return (
    <>
    <div className="page-header">
      <div className="left">{tabName}</div>
      <div className="center">{nomeLoja}</div>
      <div className="right">{nomeUsuarioLogado}</div>
    </div>
    <Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#000', height:'100px' , marginTop:'30px'}}>
        <span style={{ color: '#fff', fontWeight: 'bold', fontSize: 18 }}>TechsPDV</span>
        <span style={{ color: '#fff', fontWeight: 'bold', fontSize: 18 }}>{getDataAtual()}</span>
        <span style={{ color: '#fff', fontWeight: 'bold', fontSize: 18 }}>{tpAmb == 1 ? 'P' : 'H' }</span>
      </Header>

      </>
    
  );
};

export default PageHeader;
